import { useNavigate, useParams } from 'react-router-dom';
import { useFetchSingleModifierGroup } from 'hooks/menu/useMenuHook';
import { RenderIf } from 'utilities/helpers';
import { useState } from 'react';
import { Toasts } from 'components/toasts';
import { client } from 'utilities/db';
import { Loading } from 'components/loading';
import { Input, Button as RsuiteButton, Modal as RsuiteModal } from 'rsuite';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { TextField } from 'components/input';
import { CustomSelectDropdown } from 'components/select/multi';
import { useFetchMenusByBrand } from 'hooks/resourceHooks';

const buttonStyle = {
  padding: '6px 12px',
  borderRadius: '4px',
  cursor: 'pointer',
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
  gap: '4px'
};

export const MenuModifierDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    data: fetchModifierGroup = {},

    isLoading: fetchedModifierGroupLoading,
    refetch
  } = useFetchSingleModifierGroup(id);

  const { data: menuList } = useFetchMenusByBrand();

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    reset
  } = useForm({
    defaultValues: {
      modifiers: [{ modifier: '', modifier_price: '' }]
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'modifiers'
  });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [desiredAction, setDesiredAction] = useState('');
  const [updatedModifierName, setUpdatedModifierName] = useState('');
  const [updating, setUpdating] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedModifierId, setSelectedModifierId] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [updatedModifierPrice, setUpdatedModifierPrice] = useState(null);

  const modifierGroup = fetchModifierGroup?.modifierGroup;

  const updateModifierName = async () => {
    try {
      setUpdating(true);
      await client.post(
        `/modifierGroup/updateModifierGroup/${modifierGroup?.id}`,
        {
          name: updatedModifierName
        }
      );
      refetch();
      Toasts.success('Modifier group updated successfully');
      setIsOpenModal(false);
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    } finally {
      setUpdating(false);
    }
  };

  const deleteModifier = async () => {
    try {
      setUpdating(true);
      await client.post(`/modifierGroup/removeModifier/${modifierGroup?.id}`, {
        modifier_id: selectedModifierId
      });
      refetch();
      Toasts.success('Modifier deleted successfully');
      setOpenDeleteModal(false);
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    } finally {
      setUpdating(false);
    }
  };

  const deleteItem = async () => {
    try {
      setUpdating(true);
      await client.post(`/modifierGroup/removeItem/${modifierGroup?.id}`, {
        item_id: selectedItemId
      });
      refetch();
      Toasts.success('Item deleted successfully');
      setOpenDeleteModal(false);
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    } finally {
      setUpdating(false);
    }
  };

  const updateModifier = async () => {
    try {
      setUpdating(true);
      await client.post(`/modifierGroup/updateModifier/${modifierGroup?.id}`, {
        modifier_id: selectedModifierId,
        name: updatedModifierName,
        additional_price: +updatedModifierPrice
      });
      refetch();
      Toasts.success('Modifier updated successfully');
      setIsOpenModal(false);
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    } finally {
      setUpdating(false);
    }
  };

  const addModifier = async (data) => {
    try {
      setUpdating(true);
      await client.post(`/modifierGroup/addModifiers/${modifierGroup?.id}`, {
        modifiers: data?.modifiers
          ?.filter(
            (item) => item?.modifier !== '' && item?.modifier_price !== ''
          )
          ?.map((item) => ({
            modifier_name: item?.modifier,
            additional_price: +item?.modifier_price
          }))
      });
      refetch();
      Toasts.success('Modifier added successfully');
      setIsOpenModal(false);
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    } finally {
      setUpdating(false);
    }
  };

  const addItem = async (data) => {
    try {
      setUpdating(true);
      await client.post(`/modifierGroup/addItems/${modifierGroup?.id}`, {
        menu_items: data?.menu_items?.map((item) => item?.value)
      });
      refetch();
      Toasts.success('Item added successfully');
      setIsOpenModal(false);
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    } finally {
      setUpdating(false);
    }
  };

  const deleteModifierGroup = async () => {
    try {
      setUpdating(true);
      await client.post(
        `/modifierGroup/deleteModifierGroup/${modifierGroup?.id}`
      );
      refetch();
      navigate('/menu-engineering/modifiers');
      Toasts.success('Modifier group deleted successfully');
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    } finally {
      setUpdating(false);
    }
  };

  return (
    <>
      <article>
        <RenderIf isTrue={fetchedModifierGroupLoading}>
          <div className={`loading_full`}>
            <Loading />
          </div>
        </RenderIf>
        <RenderIf isTrue={fetchModifierGroup}>
          <div
            style={{
              marginTop: '24px',
              padding: '24px',
              backgroundColor: '#ffffff',
              borderRadius: '8px',
              boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
            }}
          >
            <div
              style={{
                fontSize: '20px',
                display: 'flex',
                alignItems: 'center',
                marginBottom: '24px',
                padding: '16px',
                border: '1px solid #e0e0e0',
                borderRadius: '6px',
                backgroundColor: '#ffffff'
              }}
            >
              <div style={{ flex: 1 }}>
                <b>{modifierGroup?.modifier_group_name}</b>
              </div>
              <div
                style={{ display: 'flex', gap: '8px', alignItems: 'center' }}
              >
                <button
                  style={{
                    ...buttonStyle,
                    backgroundColor: '#f0f0f0',
                    border: '1px solid #ddd',
                    color: '#666'
                  }}
                  onClick={() => {
                    setIsOpenModal(true);
                    setDesiredAction('modifierName');
                  }}
                >
                  Edit
                </button>
                <button
                  style={{
                    ...buttonStyle,
                    backgroundColor: '#fff0f0',
                    border: '1px solid #ffdddd',
                    color: '#d32f2f'
                  }}
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setDesiredAction('deleteModifierGroup');
                  }}
                >
                  Delete
                </button>
              </div>
            </div>

            <div
              style={{
                padding: '16px',
                border: '1px solid #e0e0e0',
                borderRadius: '6px',
                marginBottom: '24px',
                backgroundColor: '#ffffff'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '16px'
                }}
              >
                <div style={{ fontSize: '18px', fontWeight: '600' }}>
                  Options
                </div>
                <RsuiteButton
                  style={{ backgroundColor: '#a561d8', color: 'white' }}
                  appearance="primary"
                  onClick={() => {
                    reset();
                    setIsOpenModal(true);
                    setDesiredAction('addModifier');
                  }}
                >
                  Add more
                </RsuiteButton>
              </div>

              {modifierGroup?.modifiers?.map((item) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '8px',
                    borderRadius: '4px',
                    backgroundColor: 'rgba(255,255,255,0.02)',
                    ':hover': { backgroundColor: 'rgba(255,255,255,0.05)' }
                  }}
                >
                  <div>{item?.modifier_name}</div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '12px'
                    }}
                  >
                    <div style={{ minWidth: '60px', textAlign: 'right' }}>
                      ${item?.additional_price}
                    </div>
                    <div style={{ display: 'flex', gap: '8px' }}>
                      <button
                        style={{
                          ...buttonStyle,
                          backgroundColor: '#f0f0f0',
                          border: '1px solid #ddd',
                          color: '#666'
                        }}
                        onClick={() => {
                          setSelectedModifierId(item?.id);
                          setUpdatedModifierName(item?.modifier_name);
                          setUpdatedModifierPrice(item?.additional_price);
                          setIsOpenModal(true);
                          setDesiredAction('updateModifier');
                        }}
                      >
                        Edit
                      </button>
                      <button
                        style={{
                          ...buttonStyle,
                          backgroundColor: '#fff0f0',
                          border: '1px solid #ffdddd',
                          color: '#d32f2f',
                          opacity:
                            modifierGroup?.modifiers?.length === 1 ? 0.5 : 1,
                          cursor:
                            modifierGroup?.modifiers?.length === 1
                              ? 'not-allowed'
                              : 'pointer'
                        }}
                        onClick={() => {
                          if (modifierGroup?.modifiers?.length > 1) {
                            setSelectedModifierId(item?.id);
                            setOpenDeleteModal(true);
                            setDesiredAction('deleteModifier');
                          }
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div
              style={{
                padding: '16px',
                border: '1px solid #e0e0e0',
                borderRadius: '6px',
                marginBottom: '24px',
                backgroundColor: '#ffffff'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '16px'
                }}
              >
                <div style={{ fontSize: '18px', fontWeight: '600' }}>Items</div>
                <RsuiteButton
                  style={{ backgroundColor: '#a561d8', color: 'white' }}
                  appearance="primary"
                  onClick={() => {
                    reset();
                    setIsOpenModal(true);
                    setDesiredAction('addItem');
                  }}
                >
                  Add more
                </RsuiteButton>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px'
                }}
              >
                {modifierGroup?.items?.map((item) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: '8px',
                      borderRadius: '4px',
                      backgroundColor: 'rgba(255,255,255,0.02)',
                      ':hover': { backgroundColor: 'rgba(255,255,255,0.05)' }
                    }}
                  >
                    <div>{item?.menu_item_name}</div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '12px'
                      }}
                    >
                      <div style={{ minWidth: '60px', textAlign: 'right' }}>
                        ${item?.price}
                      </div>
                      <div
                        className="action-buttons"
                        style={{ display: 'flex', gap: '8px' }}
                      >
                        <button
                          style={{
                            ...buttonStyle,
                            backgroundColor: '#fff0f0',
                            border: '1px solid #ffdddd',
                            color: '#d32f2f',
                            opacity:
                              modifierGroup?.items?.length === 1 ? 0.5 : 1,
                            cursor:
                              modifierGroup?.items?.length === 1
                                ? 'not-allowed'
                                : 'pointer'
                          }}
                          onClick={() => {
                            if (modifierGroup?.items?.length > 1) {
                              setSelectedItemId(item?.id);
                              setOpenDeleteModal(true);
                              setDesiredAction('deleteItem');
                            }
                          }}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </RenderIf>

        {isOpenModal && (
          <RsuiteModal open={isOpenModal} onClose={() => setIsOpenModal(false)}>
            <RsuiteModal.Header>
              <RsuiteModal.Title>
                {desiredAction === 'modifierName'
                  ? 'Update Group Name'
                  : desiredAction === 'updateModifier'
                  ? 'Update Modifier'
                  : desiredAction === 'addItem'
                  ? 'Add Items'
                  : 'Add Modifier'}
              </RsuiteModal.Title>
            </RsuiteModal.Header>
            <RsuiteModal.Body style={{ overflowY: 'auto' }}>
              <RenderIf isTrue={desiredAction === 'modifierName'}>
                <Input
                  defaultValue={modifierGroup?.modifier_group_name}
                  onChange={(val) => setUpdatedModifierName(val)}
                />
              </RenderIf>
              <RenderIf isTrue={desiredAction === 'updateModifier'}>
                <div style={{ display: 'flex', gap: '8px' }}>
                  <div style={{ flex: 1 }}>
                    <label>Name:</label>
                    <Input
                      defaultValue={updatedModifierName}
                      onChange={(val) => setUpdatedModifierName(val)}
                    />
                  </div>
                  <div style={{ flex: 1 }}>
                    <label>Price:</label>
                    <Input
                      defaultValue={updatedModifierPrice}
                      onChange={(val) => setUpdatedModifierPrice(val)}
                    />
                  </div>
                </div>
              </RenderIf>
              <RenderIf isTrue={desiredAction === 'addModifier'}>
                <form onSubmit={handleSubmit(addModifier)}>
                  {fields?.map((field, index) => {
                    return (
                      <div className="mt_4" key={field.id}>
                        <TextField
                          type="text"
                          name={`modifiers.${index}.modifier`}
                          label={'Item Name'}
                          placeholder="Item name"
                          register={register}
                          error={
                            errors?.modifiers?.[index]?.modifier?.type ===
                            'required'
                              ? 'Item name is required'
                              : ''
                          }
                        />
                        <div className="mt_16"></div>
                        <TextField
                          type="number"
                          name={`modifiers.${index}.modifier_price`}
                          label={'Item Price'}
                          placeholder="Item price"
                          register={register}
                          error={
                            errors?.modifiers?.[index]?.modifier_price?.type ===
                            'required'
                              ? 'Modifier option price is required'
                              : ''
                          }
                        />
                        {/* <div className="mt_16"></div>
                    <CheckboxField
                      name={`modifiers.${index}.add_separate`}
                      id={`add_separate${index}`}
                      register={register}
                    >
                      Add separately (on the side)
                    </CheckboxField> */}
                        <RenderIf isTrue={fields?.length === 1}>
                          <div className="flex item_hend mt_10">
                            <button
                              type="button"
                              className="alink"
                              color="lableColor"
                              onClick={() =>
                                append({
                                  modifier: '',
                                  modifier_price: '',
                                  add_separate: false
                                })
                              }
                            >
                              + Add another item
                            </button>
                          </div>
                        </RenderIf>
                        <RenderIf
                          isTrue={
                            fields?.length > 1 && index + 1 !== fields?.length
                          }
                        >
                          <div className="flex item_hend mt_10">
                            <button
                              type="button"
                              className="alink"
                              color="lableColor"
                              onClick={() => remove(index)}
                            >
                              - Remove item
                            </button>
                          </div>
                        </RenderIf>
                        <RenderIf
                          isTrue={
                            fields?.length > 1 && index + 1 === fields?.length
                          }
                        >
                          <div className="flex item_hend gap_16 mt_10">
                            <button
                              type="button"
                              className="alink"
                              color="lableColor"
                              onClick={() =>
                                append({
                                  modifier: '',
                                  modifier_price: '',
                                  add_separate: false
                                })
                              }
                            >
                              + Add another item
                            </button>
                            <button
                              type="button"
                              className="alink"
                              color="lableColor"
                              onClick={() => remove(index)}
                            >
                              - Remove item
                            </button>
                          </div>
                        </RenderIf>
                        {errors?.modifiers?.[index]?.modifier?.type ===
                        'required' ? (
                          <div className="error"></div>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </form>
              </RenderIf>
              <RenderIf isTrue={desiredAction === 'addItem'}>
                <div style={{ minHeight: '150px', overflowY: 'auto' }}>
                  <Controller
                    name="menu_items"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomSelectDropdown
                        hookForm={true}
                        field={field}
                        isMulti={true}
                        placeholder="Choose menu items"
                        options={menuList
                          ?.filter(
                            (menu) =>
                              !modifierGroup?.items?.some(
                                (item) => item?.id === menu?.id
                              )
                          )
                          ?.map((menu) => ({
                            value: menu?.id,
                            label: menu?.menu_item_name
                          }))}
                        error={
                          errors?.link_menu_items?.type === 'required'
                            ? 'Menu item is required'
                            : ''
                        }
                      />
                    )}
                  />
                </div>
              </RenderIf>
            </RsuiteModal.Body>
            <RsuiteModal.Footer>
              <div
                style={{
                  display: 'flex',
                  gap: '8px',
                  justifyContent: 'flex-end'
                }}
              >
                <RsuiteButton
                  onClick={() => {
                    if (desiredAction === 'modifierName') {
                      updateModifierName();
                    }
                    if (desiredAction === 'updateModifier') {
                      updateModifier();
                    }
                    if (desiredAction === 'addModifier') {
                      handleSubmit(addModifier)();
                    }
                    if (desiredAction === 'addItem') {
                      handleSubmit(addItem)();
                    }
                  }}
                  type={
                    desiredAction === 'addModifier'
                      ? 'submit'
                      : desiredAction === 'addItem'
                      ? 'submit'
                      : 'button'
                  }
                  appearance="ghost"
                  loading={updating}
                >
                  {desiredAction === 'modifierName' ? 'Update' : 'Add'}
                </RsuiteButton>
                <RsuiteButton
                  onClick={() => setIsOpenModal(false)}
                  appearance="subtle"
                >
                  Cancel
                </RsuiteButton>
              </div>
            </RsuiteModal.Footer>
          </RsuiteModal>
        )}

        {openDeleteModal && (
          <RsuiteModal
            backdrop="static"
            role="alertdialog"
            open={openDeleteModal}
            onClose={() => setOpenDeleteModal(false)}
            size="xs"
          >
            <RsuiteModal.Body>
              Are you sure you want to proceed ?
            </RsuiteModal.Body>
            <RsuiteModal.Footer>
              <RsuiteButton
                onClick={() => {
                  if (desiredAction === 'deleteModifier') {
                    deleteModifier();
                  }
                  if (desiredAction === 'deleteItem') {
                    deleteItem();
                  }
                  if (desiredAction === 'deleteModifierGroup') {
                    deleteModifierGroup();
                  }
                }}
                appearance="ghost"
                color="red"
                loading={updating}
              >
                Yes
              </RsuiteButton>
              <RsuiteButton
                onClick={() => setOpenDeleteModal(false)}
                appearance="subtle"
              >
                Cancel
              </RsuiteButton>
            </RsuiteModal.Footer>
          </RsuiteModal>
        )}
      </article>
    </>
  );
};
