import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useStore } from 'store';
import { client } from 'utilities/db';
import {
  useFetchCategories,
  useFetchMenusByBrand,
  useFetchBranches
} from 'hooks/resourceHooks';
import { getUser } from 'utilities/helpers/auth';
import { RenderIf } from 'utilities/helpers';
import { AddMenuItem } from './add';
import { TextField } from 'components/input';
import { Button, PrimaryButton } from 'components/button';
import { Toasts } from 'components/toasts';
import { Ikon } from 'assets/icons';
import { Loading } from 'components/loading';
import PlaceholderImage from 'assets/Images/placeholder.png';

export const MenuItem = () => {
  const { brand, branch } = useStore((state) => state);
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      return client.post(isEdit ? `/menu/update` : `/menuItem/add`, userData);
    }
  );
  const {
    refetch: refetchMenu,
    isLoading: menuIsLoading,
    error: menuError,
    data: menuData = [],
    isSuccess: menuIsSuccess
  } = useFetchMenusByBrand();
  const navigate = useNavigate();
  const user = getUser();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [uploadImage, setUploadImage] = useState('');
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const [additionType, setAdditionType] = useState('upload');
  const [isEdit, setIsEdit] = useState(false);
  const [searchField, setSearchField] = useState('');
  const { data: allCategories } = useFetchCategories();

  const { data: branchData, isLoading: branchLoading } = useFetchBranches(
    brand?.id
  );

  useEffect(() => {
    if (!user?.id) {
      navigate('/login');
      return;
    }
  }, [user]);

  const onEditClick = (val) => {
    // setIsEdit(true)
    // setAdditionType('add')
    // setIsOpenModal(true)
    // setCategory(allCategories?.filter(item => item.id === val?.category_id)?.map(item => ({ label: item?.name, value: item?.id })))
  };

  const addMenu = (val) => {
    console.log('val', {
      brand_id: brand?.id,
      image: uploadImage,
      menu_item_level: val?.branch_ids?.length > 0 ? 'BRANCH' : 'BRAND',
      menu_item_name: val?.menu_item_name,
      menu_item_description: val?.menu_item_description,
      base_price: Number(val?.base_price),
      taxable: val?.taxable === true ? '1' : '0',
      quantity_threshold: val?.quantity_threshold,
      quantity_options: {
        force_quantity: val?.force_quantity,
        lock_quantity: val?.lock_quantity,
        hide_quantity: val?.hide_quantity
      },
      tags:
        val?.tags?.length && val?.tags?.length > 0
          ? val?.tags?.map((tag) => tag.value)
          : [],
      menu_status: val?.menu_status,
      category_id: val?.category?.value,
      category_type_id: val?.category_types?.value,
      branch_id: branch?.id,
      available_days: {
        monday: val?.monday,
        tuesday: val?.tuesday,
        wednesday: val?.wednesday,
        thursday: val?.thursday,
        friday: val?.friday,
        saturday: val?.saturday,
        sunday: val?.sunday
      },
      available_time: {
        start_time: val?.start_time,
        end_time: val?.end_time
      },
      sale_channels: {
        pos: val?.pos,
        online_store: val?.online_store,
        others: val?.others
      }
    });

    mutate({
      brand_id: brand?.id,
      image: uploadImage,
      menu_item_level: val?.branch_ids?.length > 0 ? 'BRANCH' : 'BRAND',
      menu_item_name: val?.menu_item_name,
      menu_item_description: val?.menu_item_description,
      base_price: Number(val?.base_price),
      taxable: val?.taxable === true ? '1' : '0',
      quantity_threshold: val?.quantity_threshold,
      quantity_options: {
        force_quantity: val?.force_quantity,
        lock_quantity: val?.lock_quantity,
        hide_quantity: val?.hide_quantity
      },
      tags:
        val?.tags?.length && val?.tags?.length > 0
          ? val?.tags?.map((tag) => tag.value)
          : [],
      menu_status: val?.menu_status,
      category_id: val?.category?.value,
      category_type_id: val?.category_types?.value,
      branch_id: branch?.id,
      available_days: {
        monday: val?.monday,
        tuesday: val?.tuesday,
        wednesday: val?.wednesday,
        thursday: val?.thursday,
        friday: val?.friday,
        saturday: val?.saturday,
        sunday: val?.sunday
      },
      available_time: {
        start_time: val?.start_time,
        end_time: val?.end_time
      },
      sale_channels: {
        pos: val?.pos,
        online_store: val?.online_store,
        others: val?.others
      }
    });
  };

  const onClose = () => {
    setIsOpenModal(false);
    setIsEdit(false);
  };

  useMemo(() => {
    if (isSuccess && data) {
      client
        .post(`/modifierGroup/addModifier`, {
          brand_id: brand?.id,
          branch_id: branch?.id,
          modifier_group_name: 'BASE',
          modifier_group_description: '',
          modifier_group_level: 'BRANCH',
          is_required: true,
          image: '',
          menu_items: [data?.data?.menuItem?.id],
          modifiers: [
            {
              modifier_name: 'BASE',
              modifier_description: '',
              modifier_level: 'BRANCH',
              additional_price: '0',
              calories: 0,
              image: ''
            }
          ],
          settings: {
            free_choice_quantity: '',
            free_choice: false,
            modifier_charges: ''
          },
          modifier_group_type: 'MODIFIERS'
        })
        .then((res) => {
          console.log('🚀 ~ file: index.jsx:23 ~ MenuItem ~ res:', res);
        })
        .catch((err) => {
          console.log('🚀 ~ file: index.jsx:23 ~ MenuItem ~ err:', err);
        });

      refetchMenu();
      onClose();
      Toasts.success('Menu item has been added.');
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (!isError) return;
    Toasts.error(error?.response?.data?.message);
  }, [isError]);

  const filteredMenuItem = menuData?.filter((item) => {
    return item?.menu_item_name
      ?.toLowerCase()
      ?.includes(searchField?.toLowerCase());
  });

  return (
    <>
      <div className="mt_40"></div>
      <div className="flex item_space_btn pt_10">
        <div>
          <h1>
            <strong>Menu Items</strong>
          </h1>
        </div>
        <div>
          <PrimaryButton
            disabled={allCategories?.length === 0}
            onClick={() => {
              setAdditionType('add');
              setIsOpenModal(true);
            }}
          >
            {allCategories?.length === 0
              ? 'Add category first'
              : 'Add menu item'}
          </PrimaryButton>
        </div>
      </div>

      <div className="mt_32"></div>
      <div className="box2 flex item_vcenter item_space_btn">
        <div className="w_356">
          <TextField
            onChange={(e) => setSearchField(e.target.value)}
            value={searchField}
            placeholder={'Search'}
          />
        </div>
        <div></div>
      </div>

      <div className="mt_40">
        {menuIsLoading ? (
          <div className={`loading_full`}>
            <Loading />
          </div>
        ) : (
          <div
            className="cat_grid"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '24px',
              // padding: '24px',
              margin: '0 auto',
              maxWidth: '100%',
              boxSizing: 'border-box'
              // justifyContent: 'center'
            }}
          >
            {filteredMenuItem?.map((item, i) => (
              <div
                key={i}
                className="cat_col"
                style={{
                  flex: '0 0 340px',
                  margin: '0',
                  maxWidth: '340px',
                  minWidth: '340px',
                  '@media (max-width: 768px)': {
                    flex: '0 0 100%',
                    maxWidth: '100%',
                    minWidth: 'unset'
                  }
                }}
              >
                <div
                  onClick={() => onEditClick(item)}
                  className="cat_box cat_box2 card_item1"
                  style={{
                    background: '#fff',
                    borderRadius: '12px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.08)',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    cursor: 'pointer',
                    overflow: 'hidden',
                    border: '1px solid #eee',
                    height: '100%',
                    maxHeight: '380px'
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.transform = 'translateY(-4px)';
                    e.currentTarget.style.boxShadow =
                      '0 4px 12px rgba(0,0,0,0.12)';
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.transform = 'translateY(0)';
                    e.currentTarget.style.boxShadow =
                      '0 2px 8px rgba(0,0,0,0.08)';
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      height: '180px',
                      position: 'relative',
                      backgroundColor: '#f5f5f5',
                      overflow: 'hidden'
                    }}
                  >
                    <picture
                      style={{
                        width: '100%',
                        height: '100%',
                        display: 'block'
                      }}
                    >
                      <img
                        src={item?.image || PlaceholderImage}
                        alt={item?.menu_item_name || 'Menu item'}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'contain',
                          objectPosition: 'center',
                          backgroundColor: '#f5f5f5'
                        }}
                      />
                    </picture>
                  </div>

                  <div style={{ padding: '16px' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '8px'
                      }}
                    >
                      <div
                        style={{
                          fontSize: '18px',
                          fontWeight: '600',
                          color: '#2c3e50',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '70%'
                        }}
                      >
                        {item?.menu_item_name}
                      </div>
                      <div
                        style={{
                          padding: '4px 8px',
                          borderRadius: '4px',
                          backgroundColor:
                            item?.menu_status === 1 ? '#e6f4ea' : '#fce8e8',
                          fontSize: '12px',
                          color: item?.menu_status === 1 ? '#1e7e34' : '#dc3545'
                        }}
                      >
                        {item?.menu_status === 'ACTIVE' ? 'Active' : 'Inactive'}
                      </div>
                    </div>

                    <div
                      style={{
                        position: 'relative',
                        color: '#6c757d',
                        fontSize: '14px',
                        marginBottom: '16px',
                        height: '40px',
                        overflow: 'hidden',
                        display: '-webkit-box',
                        WebkitLineClamp: '2',
                        WebkitBoxOrient: 'vertical',
                        textOverflow: 'ellipsis'
                      }}
                    >
                      {item?.menu_item_description}
                      {item?.menu_item_description?.length > 100 && (
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `/menu-engineering/item/detail/${item?.id}`
                            );
                          }}
                          style={{
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            background: '#fff',
                            border: 'none',
                            color: '#007bff',
                            cursor: 'pointer',
                            padding: '0 4px'
                          }}
                        >
                          ...more
                        </button>
                      )}
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '1px solid #eee',
                        paddingTop: '16px'
                      }}
                    >
                      <div
                        style={{
                          fontSize: '20px',
                          fontWeight: '600',
                          color: '#2c3e50'
                        }}
                      >
                        ${item?.price}
                      </div>
                      <button
                        className="alink"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/menu-engineering/item/detail/${item?.id}`);
                        }}
                        style={{
                          color: '#007bff',
                          border: 'none',
                          background: 'none',
                          padding: '8px 12px',
                          cursor: 'pointer',
                          borderRadius: '4px',
                          transition: 'background-color 0.2s'
                        }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.backgroundColor = '#f8f9fa')
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.backgroundColor =
                            'transparent')
                        }
                      >
                        View detail
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {isOpenModal && (
        <AddMenuItem
          allCategories={allCategories}
          isLoading={isLoading || uploadImageLoading}
          addMenu={addMenu}
          additionType={additionType}
          uploadImage={uploadImage}
          setUploadImageLoading={setUploadImageLoading}
          setUploadImage={setUploadImage}
          onClose={onClose}
          branchData={branchData}
          branchLoading={branchLoading}
          isOpenModal={isOpenModal}
        />
      )}
    </>
  );
};

const AddMenuCards = ({ name, heading, description, onClick, disabled }) => {
  return (
    <>
      <div
        style={{
          opacity: disabled ? 0.4 : 1,
          pointerEvents: disabled ? 'none' : 'all'
        }}
      >
        <button className="add_new_button" onClick={onClick}>
          <div>
            <div>
              <Ikon name={name} />
            </div>
            <h4>{heading}</h4>
            <p>{description}</p>
          </div>
        </button>
      </div>
    </>
  );
};
