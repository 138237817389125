import { useForm, Controller } from 'react-hook-form';
import { RenderIf } from 'utilities/helpers';
import { ModalComponent } from 'components/modal';
import { CustomSelectDropdown } from 'components/select/multi';
import ImageDropzone from 'components/imageDropzone/imageDropzone';
import { TextField } from 'components/input';
import { TextArea } from 'components/textarea';
import { Button } from 'components/button';
import { StatusRadioField, ColorRadioField } from 'components/checkbox';
import { Toggle } from 'rsuite';
import { useStore } from 'store';
import { useFetchCategories } from 'hooks/resourceHooks';

export const AddMenuCatgory = ({
  isLoading,
  addMenu,
  isOpenModal,
  onClose,
  uploadImage,
  setUploadImage,
  setUploadImageLoading,
  additionType,
  colorsList,
  branchData,
  branchLoading,
  existingCategory = null,
  action = 'add'
}) => {
  console.log('🚀 ~ existingCategory:', existingCategory);
  const { branch } = useStore((state) => state);

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch
  } = useForm({
    defaultValues: {
      name:
        action === 'update' ? existingCategory?.category?.category_name : '',
      color_id:
        action === 'update'
          ? colorsList
              ?.filter(
                (color) => color?.id === existingCategory?.category?.color_id
              )
              ?.map((color) => ({
                label: color?.color_name,
                value: color?.id
              }))
          : '',
      description:
        action === 'update'
          ? existingCategory?.category?.category_description
          : '',
      branch_ids:
        action === 'update' ? existingCategory?.category?.branch_id : [],
      category_types:
        action === 'update'
          ? existingCategory?.categoryType?.map((cat) => ({
              label: cat?.category_type_name,
              value: cat?.category_type_name
            }))
          : [],
      is_active: action === 'update' ? existingCategory?.category?.is_active : 1
    }
  });

  const { data: categoryTypesData } = useFetchCategories();

  return (
    <>
      <ModalComponent
        modal={isOpenModal}
        handleModal={onClose}
        modalMiddle={false}
        rightModal={true}
      >
        <RenderIf isTrue={additionType === 'upload'}>
          <>
            <h4>
              <span className="ff_semi">Upload template file</span>
            </h4>
            <div className="mt_40"></div>
            <div className="pt_10">
              <label className="label">Upload file *</label>
              <div className="mt_4"></div>
              <ImageDropzone
                customComponent={
                  <div className="flex item_hcenter text_center">
                    <div>
                      <h4 color="greylite">Drag &amp; drop to upload file</h4>
                      <h4 color="greylite" className="mt_16">
                        OR
                      </h4>
                      <Button
                        type="button"
                        className="button_browse mt_16"
                        label={'Browse file'}
                      />
                    </div>
                  </div>
                }
                setIsLoading={setUploadImageLoading}
                image={uploadImage}
                setImage={setUploadImage}
                large={true}
              />
            </div>
            <div className="mt_30"></div>
            <div className="flex item_vcenter">
              <Button color="bg-titary" label={'Continue'} />
              <Button
                label={'Download guidebook template'}
                color="outline-lite"
              />
            </div>
          </>
        </RenderIf>
        <RenderIf isTrue={additionType !== 'upload'}>
          <>
            <h4>
              <span className="ff_semi">
                <RenderIf isTrue={action === 'add'}>Add new category</RenderIf>
                <RenderIf isTrue={action === 'update'}>
                  Update category
                </RenderIf>
              </span>
            </h4>
            <div className="mt_40"></div>
            <div className="pt_10"></div>
            <form onSubmit={handleSubmit(addMenu)}>
              <label className="label">Upload file *</label>
              <div className="mt_4"></div>
              <ImageDropzone
                customComponent={
                  <div className="flex item_hcenter text_center">
                    <div>
                      <h4 color="greylite">Drag &amp; drop to upload file</h4>
                      <h4 color="greylite" className="mt_16">
                        OR
                      </h4>
                      <Button
                        type="button"
                        className="button_browse mt_16"
                        label={'Browse file'}
                      />
                    </div>
                  </div>
                }
                setIsLoading={setUploadImageLoading}
                image={uploadImage || existingCategory?.category?.image}
                setImage={setUploadImage}
              />
              <div className="mt_16"></div>
              <TextField
                name="name"
                label={'Category name'}
                type="text"
                error={
                  errors?.name?.type === 'required'
                    ? 'Category name is required'
                    : ''
                }
                validation={{
                  required: true
                }}
                register={register}
                required={true}
              />
              <div className="mt_16"></div>
              <TextArea
                name="description"
                label={'Description'}
                rows="7"
                register={register}
                validation={{
                  required: true
                }}
                error={
                  errors?.description?.type === 'required'
                    ? 'Description is required'
                    : ''
                }
                required={true}
              />
              <RenderIf isTrue={action === 'add'}>
                <div className="mt_16"></div>
                <Controller
                  name="category_types"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <CustomSelectDropdown
                      hookForm={false}
                      typeArray={true}
                      isMulti={true}
                      field={field}
                      label="Category types"
                      placeholder="Add category types"
                      options={categoryTypesData
                        ?.map((cat) => {
                          return cat?.category_types?.map((type) => ({
                            label: type,
                            value: type
                          }));
                        })
                        ?.flat(2)
                        ?.filter(Boolean)}
                      error={
                        errors?.category_types?.type === 'required'
                          ? 'Category types is required'
                          : ''
                      }
                      required={true}
                    />
                  )}
                />
              </RenderIf>
              <div className="mt_24"></div>
              <hr className="hr_modal_right" />
              <div className="mt_24"></div>
              <h5>
                <strong>Category settings</strong>
              </h5>
              <div className="mt_25">
                <label className="label">Category status</label>
                <div className="mt_24"></div>
                <Controller
                  name="is_active"
                  control={control}
                  render={({ field }) => (
                    <Toggle
                      checkedChildren="ACTIVE"
                      unCheckedChildren="INACTIVE"
                      {...field}
                      defaultChecked={field.value}
                    />
                  )}
                />
              </div>
              <RenderIf isTrue={action !== 'update'}>
                <div className="mt_16">
                  <Controller
                    name="branch_ids"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <CustomSelectDropdown
                        hookForm={true}
                        isMulti
                        field={field}
                        label="Category linked with branches"
                        placeholder="Choose branches"
                        options={[
                          { label: branch?.branch_name, value: branch?.id }
                        ]}
                        required={true}
                        error={
                          errors?.branch_ids?.type === 'required'
                            ? 'Branches is required'
                            : ''
                        }
                      />
                    )}
                  />
                </div>
              </RenderIf>
              <div className="mt_16"></div>
              <label className="label">Category color *</label>
              <div className="mt_4"></div>
              <div className="color_box">
                {colorsList?.map((clr, index) => (
                  <ColorRadioField
                    key={index}
                    name="color_id"
                    id={clr?.id}
                    value={clr?.id}
                    colorHex={clr?.hex}
                    register={register}
                  />
                ))}
              </div>
              <div className="mt_24"></div>
              <hr className="hr_modal_right" />
              <div className="flex item_hend gap_16 mt_30">
                <Button
                  onClick={onClose}
                  color="outline-lite"
                  label={'Cancel'}
                />
                <Button
                  isLoading={isLoading}
                  color="bg-titary"
                  type="submit"
                  label={'Continue'}
                />
              </div>
            </form>
          </>
        </RenderIf>
      </ModalComponent>
    </>
  );
};
