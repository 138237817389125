import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useStore } from 'store';
import { useFetchModifiersByBranch } from 'hooks/modifiers/useModifierHook';
import { client } from 'utilities/db';
import { getUser } from 'utilities/helpers/auth';
import { RenderIf } from 'utilities/helpers';
import { AddMenuModifier } from './add';
import { TextField } from 'components/input';
import { Button } from 'components/button';
import { ButtonDropdown } from 'components/select/button';
import { Toasts } from 'components/toasts';
import { Ikon } from 'assets/icons';
import { Loading } from 'components/loading';

export const MODIFIER_TYPE = {
  MODIFIER: 'MODIFIER',
  SIZES: 'SIZES',
  ADDONS: 'ADDONS'
};

export const MenuModifier = () => {
  const { brand, branch } = useStore((state) => state);
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      return client.post(
        isEdit ? `/modifierGroup/update/id` : `/modifierGroup/addModifier`,
        userData
      );
    }
  );
  const {
    isLoading: modifierLoading,
    refetch: refetchModifiers,
    data: modifiersList
  } = useFetchModifiersByBranch(branch?.id);
  const navigate = useNavigate();
  const user = getUser();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchtext, setSearchtext] = useState('');
  const [formData, setFormData] = useState([
    {
      option_name: '',
      option_price: '',
      add_separate: false
    }
  ]);
  const [modifierType, setModifierType] = useState(MODIFIER_TYPE.SIZES);

  useEffect(() => {
    if (!user?.id) {
      navigate('/login');
      return;
    }
  }, [user]);

  const addMenu = (val) => {
    console.log('🚀 ~ addMenu ~ val:', val);

    mutate({
      brand_id: brand?.id,
      branch_id: branch?.id,
      modifier_group_name: val?.name,
      modifier_group_description: '',
      modifier_group_level: 'BRANCH',
      is_required: val?.is_required === 'REQUIRED' ? true : false,
      image: '',
      menu_items: val?.link_menu_items?.map((item) => item?.value),
      modifiers:
        val?.modifiers?.map((item) => ({
          modifier_name: item?.modifier,
          modifier_description: '',
          modifier_level: 'BRANCH',
          additional_price: item?.modifier_price,
          calories: 0,
          image: ''
        })) || [],
      settings: {
        free_choice_quantity: val?.free_choice_quantity,
        free_choice: val?.free_choice,
        modifier_charges: val?.modifier_charges
      },
      modifier_group_type:
        modifierType === MODIFIER_TYPE.MODIFIER
          ? MODIFIER_TYPE.MODIFIER + 'S'
          : modifierType
    });
  };

  const onClose = () => {
    setIsOpenModal(false);
    setIsEdit(false);
  };

  useMemo(() => {
    if (isSuccess) {
      refetchModifiers();
      onClose();
      Toasts.success('Modifier group has been added.');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;
    Toasts.error(error?.response?.data?.message);
  }, [isError]);

  const camelCaseConverter = (str) => {
    return str
      .toLowerCase()
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
        index === 0 ? word.toUpperCase() : word.toLowerCase()
      );
  };

  return (
    <>
      <div className="mt_40"></div>

      <div
        className="add_new_grid"
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
          gap: '16px',
          marginBottom: '15px',
          marginLeft: '2px'
        }}
      >
        <AddMenuCards
          name="fileChecked"
          heading={`Add ${camelCaseConverter(MODIFIER_TYPE.SIZES)}`}
          onClick={() => {
            setModifierType(MODIFIER_TYPE.SIZES);
            setIsOpenModal(true);
          }}
        />
        <AddMenuCards
          name="fileChecked"
          heading={`Add ${camelCaseConverter(MODIFIER_TYPE.ADDONS)}`}
          onClick={() => {
            setModifierType(MODIFIER_TYPE.ADDONS);
            setIsOpenModal(true);
          }}
        />
        <AddMenuCards
          name="fileChecked"
          heading={`Add ${camelCaseConverter(MODIFIER_TYPE.MODIFIER)}`}
          onClick={() => {
            setModifierType(MODIFIER_TYPE.MODIFIER);
            setIsOpenModal(true);
          }}
        />
      </div>
      <RenderIf isTrue={modifiersList?.length > 0}>
        {/* <div className="mt_32"></div>
        <h4>
          <span className="ff_semi">Modifiers</span>
        </h4> */}
        <div className="mt_32"></div>
        <div className="box2 flex item_vcenter item_space_btn">
          <div className="w_356">
            <TextField
              value={searchtext}
              onChange={(e) => setSearchtext(e?.target?.value)}
              placeholder={'Search'}
            />
          </div>
          <div></div>
        </div>
      </RenderIf>

      <div className="pt_8">
        {modifierLoading ? (
          <div className={`loading_full`}>
            <Loading />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              flexWrap: 'wrap',
              marginBottom: '20px'
            }}
          >
            {modifiersList
              ?.filter((item) => item?.modifier_group_name !== 'BASE')
              ?.filter((item) =>
                item?.modifier_group_name
                  ?.toLowerCase()
                  ?.includes(searchtext?.toLowerCase())
              )
              ?.map((item, ind) => (
                <div key={ind}>
                  <ModifierListCard item={item} />
                </div>
              ))}
          </div>
        )}
      </div>
      {isOpenModal && (
        <AddMenuModifier
          isLoading={isLoading}
          addMenu={addMenu}
          onClose={onClose}
          isOpenModal={isOpenModal}
          formData={formData}
          setFormData={setFormData}
          modifierType={modifierType}
        />
      )}
    </>
  );
};

const AddMenuCards = ({ name, heading, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        padding: '16px',
        background: 'white',
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        width: '100%',
        hover: {
          background: '#f5f5f5',
          transform: 'translateY(-2px)',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
        }
      }}
    >
      <Ikon
        name={name}
        style={{
          color: '#666',
          fontSize: '20px'
        }}
      />
      <span
        style={{
          fontWeight: 500,
          color: '#333',
          fontSize: '15px'
        }}
      >
        {heading}
      </span>
    </button>
  );
};

const ModifierListCard = ({ item }) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        className="cat_box cat_box2 mt_5"
        style={{
          width: '300px',
          height: '320px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div className="cat_head1">
          <div className="cat_head1_top">
            <div className="head_flex1">
              <h6>
                <span className="ff_semi">{item?.modifier_group_name}</span>
                <div style={{ fontSize: '12px', marginTop: '5px' }}>
                  <span style={{ fontWeight: 'bold' }}>Linked With: </span>
                  {item?.menuItems?.length > 0 ? (
                    <>
                      {item?.menuItems
                        ?.slice(0, 2)
                        ?.map((item) => item?.menu_item_name)
                        ?.join(', ')}
                      {item?.menuItems?.length > 2 && (
                        <span
                          style={{ color: '#0066ff', cursor: 'pointer' }}
                          onClick={() =>
                            navigate(
                              `/menu-engineering/modifier/detail/${item?.id}`
                            )
                          }
                        >
                          {` +${item?.menuItems?.length - 2} more`}
                        </span>
                      )}
                    </>
                  ) : (
                    'No items linked'
                  )}
                </div>
              </h6>
            </div>
            <div className="action_btns">
              <button
                className="button button_radius"
                color="bg-primary"
                onClick={() =>
                  navigate(`/menu-engineering/modifier/detail/${item?.id}`)
                }
              >
                Details
              </button>
            </div>
          </div>
          <RenderIf
            isTrue={item?.settings?.modifier_charges === 'NOADDITIONAL'}
          >
            <div className="attach_branch">
              <div className="attach_ikon">
                <Ikon name={`attachbox`} />
              </div>
              <div>No Additional Charges</div>
            </div>
          </RenderIf>
          <RenderIf isTrue={item?.settings?.modifier_charges === 'BYOPTION'}>
            <div className="attach_branch">
              <div className="attach_ikon">
                <Ikon name={`attachbox`} />
              </div>
              <div>Per option price</div>
            </div>
          </RenderIf>
          <RenderIf isTrue={item?.settings?.modifier_charges === 'BYGROUP'}>
            <div className="attach_branch">
              <div className="attach_ikon">
                <Ikon name={`attachbox`} />
              </div>
              <div>Per modifier group price</div>
            </div>
          </RenderIf>
        </div>
        <div
          className="modifier-items-container"
          style={{
            flex: 1,
            overflow: 'auto',
            padding: '0 16px'
          }}
        >
          <div
            className="modifier_item_header"
            style={{ padding: '12px 0', borderBottom: '2px solid #eee' }}
          >
            <h6 className="ff_semi">Modifier Options</h6>
          </div>

          {item?.modifier?.slice(0, 3)?.map((data, index) => (
            <div
              key={index}
              className="modifier_item"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '8px 0',
                borderBottom:
                  index !== item.modifier.length - 1 ? '1px solid #eee' : 'none'
              }}
            >
              <div style={{ flex: 1 }}>
                <span className="ff_semi">{data?.modifier_name}</span>
              </div>
              <div>
                <span
                  className="ff_semi"
                  style={{
                    color: '#666',
                    backgroundColor: '#f5f5f5',
                    padding: '4px 8px',
                    borderRadius: '4px'
                  }}
                >
                  ${data?.additional_price}
                </span>
              </div>
            </div>
          ))}
          {item?.modifier?.length > 3 && (
            <div
              className="view-more"
              style={{
                padding: '12px 0',
                textAlign: 'center',
                cursor: 'pointer',
                color: '#0066ff',
                fontWeight: 500
              }}
              onClick={() =>
                navigate(`/menu-engineering/modifier/detail/${item?.id}`)
              }
            >
              View {item?.modifier?.length - 3} more items
            </div>
          )}
        </div>
      </div>
    </>
  );
};
