import { useState } from 'react';
import { Controller, useForm, useFieldArray } from 'react-hook-form';
import { useFetchMenusByBrand } from 'hooks/resourceHooks';
import { RenderIf } from 'utilities/helpers';
import { ModalComponent } from 'components/modal';
import { CustomSelectDropdown } from 'components/select/multi';
import { TextField } from 'components/input';
import { CheckboxField, IsRequiredRadioField } from 'components/checkbox';
import { RadioButton } from 'components/radiobutton';
import { Button } from 'components/button';
import { MODIFIER_TYPE } from '..';

export const AddMenuModifier = ({
  isLoading,
  addMenu,
  onClose,
  isOpenModal,
  formData,
  setFormData,
  existingModifierGroup,
  action = 'add',
  modifierType = MODIFIER_TYPE.MODIFIER
}) => {
  console.log('🚀 ~ modifierType:', modifierType);
  const { data: menuList } = useFetchMenusByBrand();
  const [freeChoice, setFreeChoice] = useState(
    action === 'update' && existingModifierGroup?.settings?.free_choice_quantity
      ? true
      : false
  );
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch
  } = useForm({
    defaultValues: {
      name:
        action === 'update' ? existingModifierGroup?.modifier_group_name : '',
      is_required: 'OPTIONAL',
      modifiers: [{ modifier: '', modifier_price: '', add_separate: false }],
      free_choice:
        action === 'update' ? existingModifierGroup?.settings?.free_choice : '',
      free_choice_quantity:
        action === 'update'
          ? existingModifierGroup?.settings?.free_choice_quantity
          : '',
      modifier_charges:
        action === 'update'
          ? existingModifierGroup?.settings?.modifier_charges
          : '',
      link_menu_items:
        action === 'update'
          ? menuList
              ?.filter(
                (menu) =>
                  menu.id ===
                  existingModifierGroup?.modifiers?.[0]?.menu_item_id
              )
              ?.map((menu) => ({
                value: menu.id,
                label: menu.menu_item_name
              }))
          : ''
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'modifiers'
  });

  return (
    <>
      <ModalComponent
        modal={isOpenModal}
        handleModal={onClose}
        modalMiddle={false}
        rightModal={true}
      >
        <h4>
          <span className="ff_semi">
            <RenderIf isTrue={action === 'add'}>
              Add {modifierType?.toLocaleLowerCase()}
            </RenderIf>
            <RenderIf isTrue={action === 'update'}>
              Update {modifierType?.toLocaleLowerCase()}
            </RenderIf>
          </span>
        </h4>
        <div className="mt_24"></div>
        <form onSubmit={handleSubmit(addMenu)}>
          <TextField
            name="name"
            label={'Group Name'}
            placeholder={`${modifierType?.toLocaleLowerCase()}`}
            type="text"
            error={
              errors?.name?.type === 'required' ? 'Group name is required' : ''
            }
            validation={{
              required: true
            }}
            register={register}
            required
          />

          <RenderIf isTrue={action !== 'update'}>
            {/* <RenderIf isTrue={modifierType !== MODIFIER_TYPE.ADDONS}>
              <div className="mt_25">
                <label className="label">
                  Is this group optional or mandatory?
                </label>
                <div className="mt_4"></div>
                <IsRequiredRadioField name="is_required" register={register} />
              </div>
            </RenderIf> */}

            <div className="mt_25"></div>
            <h6>
              <strong>Options</strong>
            </h6>
            <div>
              {fields?.map((field, index) => {
                return (
                  <div className="mt_4" key={field.id}>
                    <TextField
                      type="text"
                      name={`modifiers.${index}.modifier`}
                      label={'Item Name'}
                      placeholder="Item name"
                      register={register}
                      error={
                        errors?.modifiers?.[index]?.modifier?.type ===
                        'required'
                          ? 'Item name is required'
                          : ''
                      }
                    />
                    <div className="mt_16"></div>
                    <TextField
                      type="number"
                      name={`modifiers.${index}.modifier_price`}
                      label={'Item Price'}
                      placeholder="Item price"
                      register={register}
                      error={
                        errors?.modifiers?.[index]?.modifier_price?.type ===
                        'required'
                          ? 'Modifier option price is required'
                          : ''
                      }
                    />
                    {/* <div className="mt_16"></div>
                    <CheckboxField
                      name={`modifiers.${index}.add_separate`}
                      id={`add_separate${index}`}
                      register={register}
                    >
                      Add separately (on the side)
                    </CheckboxField> */}
                    <RenderIf isTrue={fields?.length === 1}>
                      <div className="flex item_hend mt_10">
                        <button
                          type="button"
                          className="alink"
                          color="lableColor"
                          onClick={() =>
                            append({
                              modifier: '',
                              modifier_price: '',
                              add_separate: false
                            })
                          }
                        >
                          + Add another item
                        </button>
                      </div>
                    </RenderIf>
                    <RenderIf
                      isTrue={
                        fields?.length > 1 && index + 1 !== fields?.length
                      }
                    >
                      <div className="flex item_hend mt_10">
                        <button
                          type="button"
                          className="alink"
                          color="lableColor"
                          onClick={() => remove(index)}
                        >
                          - Remove item
                        </button>
                      </div>
                    </RenderIf>
                    <RenderIf
                      isTrue={
                        fields?.length > 1 && index + 1 === fields?.length
                      }
                    >
                      <div className="flex item_hend gap_16 mt_10">
                        <button
                          type="button"
                          className="alink"
                          color="lableColor"
                          onClick={() =>
                            append({
                              modifier: '',
                              modifier_price: '',
                              add_separate: false
                            })
                          }
                        >
                          + Add another item
                        </button>
                        <button
                          type="button"
                          className="alink"
                          color="lableColor"
                          onClick={() => remove(index)}
                        >
                          - Remove item
                        </button>
                      </div>
                    </RenderIf>
                    {errors?.modifiers?.[index]?.modifier?.type ===
                    'required' ? (
                      <div className="error"></div>
                    ) : (
                      ''
                    )}
                  </div>
                );
              })}
            </div>
            <div className="mt_32"></div>
            <hr className="hr_modal_right" />
            <div className="mt_32"></div>
          </RenderIf>

          <RenderIf isTrue={modifierType === MODIFIER_TYPE.MODIFIER}>
            {/* <h5>
              <strong>Group Settings</strong>
            </h5> */}
            {/* <div className="mt_24">
              <CheckboxField
                name="free_choice"
                id="free_choice"
                onChange={() => setFreeChoice(!freeChoice)}
                register={register}
              >
                I want to set free choices for this group
              </CheckboxField>
              <RenderIf isTrue={freeChoice}>
                <div className="mt_24"></div>
                <TextField
                  name="free_choice_quantity"
                  label={'Free Choice'}
                  type="text"
                  error={
                    errors?.free_choice_quantity?.type === 'required'
                      ? 'Free choice is required'
                      : ''
                  }
                  validation={{
                    required: true
                  }}
                  register={register}
                  required
                />
                <div className="pt_8"></div>
              </RenderIf>
              <div className="mt_25"></div>
              <RadioButton
                wrapClass="radio_rows"
                labels={[
                  {
                    name: 'modifier_charges',
                    id: 'no_additional',
                    value: 'NOADDITIONAL',
                    label: 'No additional charges'
                  },
                  {
                    name: 'modifier_charges',
                    id: 'price_byoption',
                    value: 'BYOPTION',
                    label: 'Additional charges (price set by group items)'
                  },
                  {
                    name: 'modifier_charges',
                    id: 'price_by_group',
                    value: 'BYGROUP',
                    label: 'Additional charges (price set by group)'
                  }
                ]}
                register={register}
              />
            </div>
            <div className="mt_32"></div>
            <hr className="hr_modal_right" /> */}
          </RenderIf>
          <div className="mt_25"></div>
          <Controller
            name="link_menu_items"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <CustomSelectDropdown
                hookForm={true}
                field={field}
                isMulti={true}
                label="Menu Items Linked with this Group"
                placeholder="Choose menu item"
                options={menuList?.map((country) => ({
                  value: country?.id,
                  label: country?.menu_item_name
                }))}
                error={
                  errors?.link_menu_items?.type === 'required'
                    ? 'Menu item is required'
                    : ''
                }
              />
            )}
          />

          <div className="flex item_hend gap_16 mt_32">
            <Button onClick={onClose} color="outline-lite" label={'Cancel'} />
            <Button
              isLoading={isLoading}
              color="bg-titary"
              type="submit"
              label={'Continue'}
            />
          </div>
        </form>
      </ModalComponent>
    </>
  );
};
