import { useNavigate, useParams } from 'react-router-dom';
import { useFetchSingleMenuItem } from 'hooks/menu/useMenuHook';
import { RenderIf } from 'utilities/helpers';
import { useStore } from 'store';
import { Button } from 'components/button';
import { AddMenuItem } from '../add';
import { useState } from 'react';
import { useFetchBranches, useFetchCategories } from 'hooks/resourceHooks';
import { Toasts } from 'components/toasts';
import { client } from 'utilities/db';
import { Loading } from 'components/loading';

export const MenuItemDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { brand } = useStore((state) => state);
  const {
    data: fetchMenuItem = {},
    isLoading: fetchedMenuItemLoading,
    refetch
  } = useFetchSingleMenuItem(`menuItem/${id}`, brand?.id, id);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [uploadImage, setUploadImage] = useState('');
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const { data: allCategories } = useFetchCategories();
  const { data: branchData, isLoading: branchLoading } = useFetchBranches(
    brand?.id
  );

  console.log('🚀 ~ MenuItemDetail ~ fetchMenuItem:', fetchMenuItem);

  const containerStyle = {
    padding: '2rem',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
    margin: '20px'
  };

  const imageStyle = {
    width: '100%',
    height: '400px',
    objectFit: 'contain',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    backgroundColor: '#f8f9fa'
  };

  const buttonContainerStyle = {
    display: 'flex',
    gap: '12px',
    marginBottom: '24px'
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 0',
    borderBottom: '1px solid #eee'
  };

  const sectionStyle = {
    marginTop: '16px',
    padding: '12px 0'
  };

  const badgeStyle = {
    backgroundColor: '#f0f0f0',
    padding: '6px 12px',
    borderRadius: '16px',
    margin: '4px',
    fontSize: '14px'
  };

  const updateMenu = async (val) => {
    try {
      console.log('🚀 ~ updateMenu ~ val:', {
        id: fetchMenuItem?.id,
        brand_id: brand?.id,
        image: uploadImage || fetchMenuItem?.image,
        old_menu_item_name: fetchMenuItem?.menu_item_name,
        menu_item_level: val?.branch_ids?.length > 0 ? 'BRANCH' : 'BRAND',
        menu_item_name: val?.menu_item_name,
        menu_item_description: val?.menu_item_description,
        base_price: Number(val?.base_price),
        taxable: val?.taxable === true ? '1' : '0',
        quantity_threshold: val?.quantity_threshold,
        quantity_options: {
          force_quantity: val?.force_quantity,
          lock_quantity: val?.lock_quantity,
          hide_quantity: val?.hide_quantity
        },
        tags:
          val?.tags?.length && val?.tags?.length > 0
            ? val?.tags?.map((tag) => tag.value)
            : [],
        menu_status: val?.menu_status,
        category_id: val?.category?.value,
        category_type_id: val?.category_types?.value,
        available_days: {
          monday: val?.monday,
          tuesday: val?.tuesday,
          wednesday: val?.wednesday,
          thursday: val?.thursday,
          friday: val?.friday,
          saturday: val?.saturday,
          sunday: val?.sunday
        },
        available_time: {
          start_time: val?.start_time,
          end_time: val?.end_time
        },
        sale_channels: {
          pos: val?.pos,
          online_store: val?.online_store,
          others: val?.others
        }
      });

      await client.post(`/menuItem/update`, {
        id: fetchMenuItem?.id,
        brand_id: brand?.id,
        image: uploadImage || fetchMenuItem?.image,
        old_menu_item_name: fetchMenuItem?.menu_item_name,
        menu_item_level: val?.branch_ids?.length > 0 ? 'BRANCH' : 'BRAND',
        menu_item_name: val?.menu_item_name,
        menu_item_description: val?.menu_item_description,
        base_price: Number(val?.base_price),
        taxable: val?.taxable === true ? '1' : '0',
        quantity_threshold: val?.quantity_threshold,
        quantity_options: {
          force_quantity: val?.force_quantity,
          lock_quantity: val?.lock_quantity,
          hide_quantity: val?.hide_quantity
        },
        tags:
          val?.tags?.length && val?.tags?.length > 0
            ? val?.tags?.map((tag) => tag.value)
            : [],
        menu_status: val?.menu_status,
        category_id: val?.category?.value,
        category_type_id: val?.category_types?.value,
        available_days: {
          monday: val?.monday,
          tuesday: val?.tuesday,
          wednesday: val?.wednesday,
          thursday: val?.thursday,
          friday: val?.friday,
          saturday: val?.saturday,
          sunday: val?.sunday
        },
        available_time: {
          start_time: val?.start_time,
          end_time: val?.end_time
        },
        sale_channels: {
          pos: val?.pos,
          online_store: val?.online_store,
          others: val?.others
        }
      });
      setIsOpenModal(false);
      refetch();
      Toasts.success('Menu Item updated successfully');
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this menu item?')) {
      try {
        await client.post(`/menuItem/delete`, {
          id: fetchMenuItem?.id
        });
        Toasts.success('Menu Item deleted successfully');
        navigate('/menu-engineering/items');
        // Add navigation logic here if needed
      } catch (error) {
        Toasts.error(error?.response?.data?.message || 'Something went wrong');
      }
    }
  };

  return (
    <>
      <article style={containerStyle}>
        <RenderIf isTrue={fetchedMenuItemLoading}>
          <div className={`loading_full`}>
            <Loading />
          </div>
        </RenderIf>
        <RenderIf isTrue={!fetchedMenuItemLoading}>
          <div className="grid grid_gap30">
            <div className="collg5">
              <picture>
                <source
                  srcSet={
                    fetchMenuItem?.image ||
                    'https://placehold.co/600x400/e2e8f0/e2e8f0.png'
                  }
                />
                <img
                  src={
                    fetchMenuItem?.image ||
                    'https://placehold.co/600x400/e2e8f0/e2e8f0.png'
                  }
                  alt={fetchMenuItem?.menu_item_name || 'Menu item'}
                  style={imageStyle}
                />
              </picture>
            </div>

            <div className="collg7">
              <div style={buttonContainerStyle}>
                <Button
                  color="bg-titary"
                  label={'Update menu item'}
                  onClick={() => setIsOpenModal(!isOpenModal)}
                />
                <Button
                  color="bg-danger"
                  label={'Delete menu item'}
                  onClick={handleDelete}
                />
              </div>

              <div style={headerStyle}>
                <h1 style={{ fontSize: '28px', fontWeight: '700' }}>
                  {fetchMenuItem?.menu_item_name}
                </h1>
                <h2
                  style={{
                    color: '#2c5282',
                    fontSize: '26px',
                    fontWeight: '600'
                  }}
                >
                  ${fetchMenuItem?.price}
                </h2>
              </div>

              <div style={sectionStyle}>
                <h6
                  style={{
                    fontSize: '16px',
                    fontWeight: '600',
                    marginBottom: '8px',
                    color: '#4a5568'
                  }}
                >
                  Description
                </h6>
                <p style={{ color: '#4a5568', lineHeight: '1.6' }}>
                  {fetchMenuItem?.menu_item_description ||
                    'No description available'}
                </p>
              </div>

              <div style={sectionStyle}>
                <p style={{ color: '#4a5568' }}>Menu item status:</p>
                <h6 style={{ fontSize: '16px', fontWeight: '600' }}>
                  <b>
                    {fetchMenuItem?.menu_status === true
                      ? 'Activated'
                      : 'Deactivated'}
                  </b>
                </h6>
              </div>

              <div style={sectionStyle}>
                <h6 style={{ marginBottom: '12px', fontSize: '16px' }}>
                  <strong>Linked category:</strong>
                </h6>
                <b>{fetchMenuItem?.category?.category_name}</b>
              </div>

              <RenderIf isTrue={fetchMenuItem?.categoryTypes?.length > 0}>
                <div style={sectionStyle}>
                  <h6 style={{ marginBottom: '12px', fontSize: '16px' }}>
                    <strong>Linked category types:</strong>
                  </h6>
                  {fetchMenuItem?.categoryTypes?.map((type, index) => (
                    <p key={index} style={{ marginBottom: '8px' }}>
                      <span style={{ fontWeight: '600' }}>
                        {type?.category_type_name}
                      </span>
                    </p>
                  ))}
                </div>
              </RenderIf>

              <RenderIf isTrue={fetchMenuItem?.tags?.length > 0}>
                <div style={sectionStyle}>
                  <h6 style={{ marginBottom: '12px', fontSize: '16px' }}>
                    <strong>Tags:</strong>
                  </h6>
                  <div
                    style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}
                  >
                    {fetchMenuItem?.tags?.map((tag, i) => (
                      <div key={i} style={badgeStyle}>
                        {tag}
                      </div>
                    ))}
                  </div>
                </div>
              </RenderIf>

              <div style={sectionStyle}>
                <h6 style={{ marginBottom: '12px', fontSize: '16px' }}>
                  <strong>Available days:</strong>
                </h6>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                  <RenderIf
                    isTrue={fetchMenuItem?.availability_days?.monday === true}
                  >
                    <div style={badgeStyle}>
                      <span style={{ fontWeight: '600' }}>Monday</span>
                    </div>
                  </RenderIf>
                  <RenderIf
                    isTrue={fetchMenuItem?.availability_days?.tuesday === true}
                  >
                    <div style={badgeStyle}>
                      <span style={{ fontWeight: '600' }}>Tuesday</span>
                    </div>
                  </RenderIf>
                  <RenderIf
                    isTrue={
                      fetchMenuItem?.availability_days?.wednesday === true
                    }
                  >
                    <div style={badgeStyle}>
                      <span style={{ fontWeight: '600' }}>Wednesday</span>
                    </div>
                  </RenderIf>
                  <RenderIf
                    isTrue={fetchMenuItem?.availability_days?.thursday === true}
                  >
                    <div style={badgeStyle}>
                      <span style={{ fontWeight: '600' }}>Thursday</span>
                    </div>
                  </RenderIf>
                  <RenderIf
                    isTrue={fetchMenuItem?.availability_days?.friday === true}
                  >
                    <div style={badgeStyle}>
                      <span style={{ fontWeight: '600' }}>Friday</span>
                    </div>
                  </RenderIf>
                  <RenderIf
                    isTrue={fetchMenuItem?.availability_days?.saturday === true}
                  >
                    <div style={badgeStyle}>
                      <span style={{ fontWeight: '600' }}>Saturday</span>
                    </div>
                  </RenderIf>
                  <RenderIf
                    isTrue={fetchMenuItem?.availability_days?.sunday === true}
                  >
                    <div style={badgeStyle}>
                      <span style={{ fontWeight: '600' }}>Sunday</span>
                    </div>
                  </RenderIf>
                </div>
              </div>

              <RenderIf isTrue={fetchMenuItem?.availability_time?.start_time}>
                <div style={sectionStyle}>
                  <p style={{ color: '#4a5568' }}>
                    Start time:
                    <b style={{ fontWeight: '600' }}>
                      {fetchMenuItem?.availability_time?.start_time}
                    </b>
                  </p>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px 0'
                  }}
                >
                  <p style={{ color: '#4a5568' }}>
                    End time:
                    <b style={{ fontWeight: '600' }}>
                      {fetchMenuItem?.availability_time?.end_time}
                    </b>
                  </p>
                </div>
              </RenderIf>
            </div>
          </div>
        </RenderIf>
        {isOpenModal && (
          <AddMenuItem
            allCategories={allCategories}
            isLoading={uploadImageLoading}
            addMenu={updateMenu}
            additionType=""
            uploadImage={uploadImage}
            setUploadImageLoading={setUploadImageLoading}
            setUploadImage={setUploadImage}
            onClose={() => setIsOpenModal(false)}
            branchData={branchData}
            branchLoading={branchLoading}
            isOpenModal={isOpenModal}
            existingMenu={fetchMenuItem}
            action="update"
          />
        )}
      </article>
    </>
  );
};
