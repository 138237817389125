import { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useStore } from 'store';
import { client } from 'utilities/db';
import { getUser } from 'utilities/helpers/auth';
import { RenderIf } from 'utilities/helpers';
import { useFetchCategoriesAll, useFetchColors } from 'hooks/resourceHooks';
import { useFetchBranches } from 'hooks/resourceHooks';
import { AddMenuCatgory } from './add';
import { TextField } from 'components/input';
import { Button, PrimaryButton } from 'components/button';
import { Toasts } from 'components/toasts';
import { Ikon } from 'assets/icons';
import { Loading } from 'components/loading';
import PlaceholderImage from 'assets/Images/placeholder.png';

export const MenuCategory = () => {
  const { brand, branch } = useStore((state) => state);
  const { mutate, isLoading, isSuccess, isError, error, data } = useMutation(
    (userData) => {
      return client.post(
        isEdit ? `/category/update/` : `/category/add`,
        userData
      );
    }
  );
  const { data: colorsList } = useFetchColors();
  const { data: branchData, isLoading: branchLoading } = useFetchBranches(
    brand?.id
  );
  const {
    refetch: refetchMenu,
    isLoading: menuIsLoading,
    error: menuError,
    data: menuData = [],
    isSuccess: menuIsSuccess
  } = useFetchCategoriesAll();
  const navigate = useNavigate();
  const user = getUser();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [uploadImage, setUploadImage] = useState('');
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const [additionType, setAdditionType] = useState('upload');
  const [isEdit, setIsEdit] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const allMenus = menuData?.filter((item) =>
    item.category_name?.toLowerCase().includes(searchValue.toLowerCase())
  );

  useEffect(() => {
    if (!user?.id) {
      navigate('/login');
      return;
    }
  }, [user, navigate]);

  const onEditClick = (val) => {
    // setIsEdit(true);
    // setselectedCategory(val);
    // setAdditionType('add');
    // setIsOpenModal(true);
    // setIsActivate(val?.is_active);
  };

  const addMenu = (val) => {
    let typesArr = [{ name: '' }];
    typesArr =
      val?.category_types?.length &&
      val?.category_types?.map((cat) => {
        return {
          name: cat?.label
        };
      });
    mutate({
      image: uploadImage,
      name: val?.name,
      description: val?.description,
      category_types: typesArr,
      is_active: val?.is_active,
      color_id: val?.color_id,
      is_deleted: false,
      brand_id: brand?.id,
      branch_ids: [branch?.id],
      category_level: val?.branch_ids?.length > 0 ? 'BRANCH' : 'BRAND'
    });
  };

  const onClose = () => {
    setIsOpenModal(false);
    setIsEdit(false);
    setUploadImage('');
  };

  useMemo(() => {
    if (isSuccess) {
      refetchMenu();
      onClose();
      Toasts.success('Category has been added.');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;
    Toasts.error(
      error?.response?.data?.message ||
        'Some thing went wrong please try again later'
    );
  }, [isError]);

  return (
    <>
      <div className="mt_40"></div>
      <div
        className="flex item_space_btn pt_10"
        style={{ alignItems: 'center' }}
      >
        <div>
          <h1 style={{ fontSize: '24px', fontWeight: 600 }}>Categories</h1>
        </div>
        <PrimaryButton
          color="primary"
          onClick={() => {
            setAdditionType('add');
            setIsOpenModal(true);
          }}
        >
          Add Category
        </PrimaryButton>
      </div>

      <div className="mt_32"></div>
      <div className="box2 flex item_vcenter item_space_btn">
        <div className="w_356">
          <TextField
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            placeholder={'Search categories...'}
          />
        </div>
        <div></div>
      </div>

      <div className="mt_40">
        {menuIsLoading ? (
          <div className={`loading_full`}>
            <Loading />
          </div>
        ) : (
          <div className="cat_grid">
            {allMenus?.map((item, i) => (
              <div key={i} className="cat_col">
                {colorsList?.map((color, index) =>
                  color?.id === item?.color_id ? (
                    <div
                      key={`${i}-${index}`}
                      onClick={() => onEditClick(item)}
                      className="cat_box"
                      style={{
                        borderLeft: `${color?.hex} 6px solid`,
                        padding: '20px',
                        borderRadius: '8px',
                        backgroundColor: '#fff',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        cursor: 'pointer',
                        transition: 'transform 0.2s ease',
                        ':hover': {
                          transform: 'translateY(-2px)'
                        }
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-start',
                          marginBottom: '12px'
                        }}
                      >
                        <RenderIf isTrue={item?.image}>
                          <picture
                            className="cat_img"
                            style={{
                              width: '60px',
                              height: '60px',
                              borderRadius: '8px',
                              overflow: 'hidden'
                            }}
                          >
                            <img
                              src={item?.image || PlaceholderImage}
                              alt=""
                              style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover'
                              }}
                            />
                          </picture>
                        </RenderIf>
                        <div
                          style={{
                            padding: '4px 8px',
                            borderRadius: '12px',
                            backgroundColor: item?.is_active
                              ? '#e6f4ea'
                              : '#fce8e8',
                            color: item?.is_active ? '#137333' : '#c5221f',
                            fontSize: '12px',
                            fontWeight: 500
                          }}
                        >
                          {item?.is_active ? 'Active' : 'Inactive'}
                        </div>
                      </div>

                      <div className="cat_head" style={{ marginBottom: '8px' }}>
                        <div
                          style={{
                            fontSize: '18px',
                            fontWeight: 600,
                            marginBottom: '8px'
                          }}
                        >
                          {item?.category_name}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            gap: '8px',
                            flexWrap: 'wrap'
                          }}
                        >
                          {item?.CategoryTypes?.slice(0, 2)?.map(
                            (type, index) => (
                              <div
                                key={index}
                                style={{
                                  padding: '4px 12px',
                                  borderRadius: '16px',
                                  backgroundColor: '#f1f3f4',
                                  fontSize: '12px',
                                  color: '#5f6368',
                                  fontWeight: 500
                                }}
                              >
                                {type?.category_type_name}
                              </div>
                            )
                          )}
                          {item?.CategoryTypes?.length > 2 && (
                            <div
                              style={{
                                padding: '4px 12px',
                                borderRadius: '16px',
                                backgroundColor: '#f1f3f4',
                                fontSize: '12px',
                                color: '#5f6368',
                                fontWeight: 500
                              }}
                            >
                              +{item?.CategoryTypes?.length - 2} more
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        style={{
                          fontSize: '14px',
                          color: '#5f6368',
                          marginBottom: '16px',
                          display: '-webkit-box',
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: 'vertical',
                          overflow: 'hidden'
                        }}
                      >
                        {item?.category_description}
                      </div>

                      <div className="flex item_space_btn">
                        <div></div>
                        <button
                          className="view-detail-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate(
                              `/menu-engineering/category/detail/${item?.id}`
                            );
                          }}
                          style={{
                            padding: '8px 16px',
                            borderRadius: '6px',
                            backgroundColor: '#f8f9fa',
                            border: '1px solid #a561d8',
                            color: '#a561d8',
                            fontSize: '14px',
                            fontWeight: 500,
                            cursor: 'pointer',
                            transition: 'all 0.2s ease'
                          }}
                          onMouseOver={(e) => {
                            e.currentTarget.style.backgroundColor = '#f1f3f4';
                            e.currentTarget.style.borderColor = '#a561d8';
                          }}
                          onMouseOut={(e) => {
                            e.currentTarget.style.backgroundColor = '#f8f9fa';
                            e.currentTarget.style.borderColor = '#a561d8';
                          }}
                        >
                          View detail
                        </button>
                      </div>
                    </div>
                  ) : null
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      {isOpenModal && (
        <AddMenuCatgory
          branchData={branchData}
          branchLoading={branchLoading}
          isLoading={isLoading || uploadImageLoading}
          addMenu={addMenu}
          additionType={additionType}
          uploadImage={uploadImage}
          setUploadImageLoading={setUploadImageLoading}
          setUploadImage={setUploadImage}
          isOpenModal={isOpenModal}
          onClose={onClose}
          colorsList={colorsList}
        />
      )}
    </>
  );
};
