import { useParams } from 'react-router-dom';
import { useFetchSingleCategory } from 'hooks/menu/useMenuHook';
import { RenderIf } from 'utilities/helpers';
import { AddMenuCatgory } from '../add';
import { useState } from 'react';
import { useFetchBranches, useFetchColors } from 'hooks/resourceHooks';
import { useStore } from 'store';
import { Button } from 'components/button';
import { Toasts } from 'components/toasts';
import { client } from 'utilities/db';
import { Loading } from 'components/loading';
import { Input, Button as RsuiteButton, Modal as RsuiteModal } from 'rsuite';
import { TextField } from 'components/input';
import { useFieldArray, useForm } from 'react-hook-form';

export const CategoryDetail = () => {
  const { brand } = useStore((state) => state);
  const { id } = useParams();
  const {
    data: fetchCategory = {},
    isLoading: fetchedCategoryLoading,
    refetch
  } = useFetchSingleCategory(id);

  const { data: colorsList } = useFetchColors();
  const { data: branchData, isLoading: branchLoading } = useFetchBranches(
    brand?.id
  );

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [uploadImage, setUploadImage] = useState('');
  const [uploadImageLoading, setUploadImageLoading] = useState(false);
  const [additionType, setAdditionType] = useState('');
  const [openTypesModal, setOpenTypesModal] = useState(false);
  const [desiredAction, setDesiredAction] = useState('');
  const [updatedTypeName, setUpdatedTypeName] = useState('');
  const [updating, setUpdating] = useState(false);
  const [categoryTypeId, setCategoryTypeId] = useState(null);

  const addMenu = async (val) => {
    console.log('🚀 ~ updateCategory ~ val:', val);
    try {
      await client.post(`/category/update`, {
        id: +id,
        image: uploadImage || fetchCategory?.image,
        name: val?.name,
        description: val?.description,
        is_active: val?.is_active ? 1 : 0,
        color_id: !Array.isArray(val?.color_id)
          ? val?.color_id
          : val?.color_id?.[0]?.value || '7'
      });
      refetch();
      Toasts.success('Category updated successfully');
      setIsOpenModal(false);
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    }
  };

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    reset
  } = useForm({
    defaultValues: {
      types: [{ type: '' }]
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'types'
  });

  const addTypes = async (val) => {
    const types = val?.types?.map((type) => type?.type);
    if (types?.length === 0) {
      Toasts.error('Please add at least one type');
      return;
    }
    try {
      setUpdating(true);
      await client.post(`/category/addCategoryTypes`, {
        category_id: +id,
        category_types: types?.map((type) => ({
          name: type
        }))
      });
      refetch();
      Toasts.success('Types added successfully');
      setOpenTypesModal(false);
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    } finally {
      setUpdating(false);
    }
  };

  const updateType = async () => {
    try {
      setUpdating(true);
      await client.post(`/category/updateCategoryTypes`, {
        category_type_id: +categoryTypeId,
        category_type_name: updatedTypeName
      });
      refetch();
      Toasts.success('Type updated successfully');
      setOpenTypesModal(false);
    } catch (error) {
      Toasts.error(error?.response?.data?.message || 'Something went wrong');
    } finally {
      setUpdating(false);
    }
  };

  console.log('🚀 ~ CategoryDetail ~ errors:', errors);

  return (
    <article
      style={{
        padding: '2rem',
        maxWidth: '1200px',
        margin: '0 auto',
        backgroundColor: '#fff',
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
        marginTop: '2rem'
      }}
    >
      <RenderIf isTrue={fetchedCategoryLoading}>
        <div className="loading_full">
          <Loading />
        </div>
      </RenderIf>

      <RenderIf isTrue={!fetchedCategoryLoading}>
        <div
          className="grid grid_gap30"
          style={{ display: 'flex', gap: '3rem' }}
        >
          <RenderIf isTrue={fetchCategory?.category?.image}>
            <div
              className="collg5"
              style={{ position: 'relative', flex: '0 0 40%' }}
            >
              <picture
                style={{
                  width: '100%',
                  borderRadius: '12px',
                  overflow: 'hidden',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                  display: 'block'
                }}
              >
                <source srcSet={fetchCategory?.category?.image} />
                <img
                  src={fetchCategory?.category?.image}
                  alt={fetchCategory?.category?.category_name}
                  style={{
                    width: '100%',
                    objectFit: 'contain',
                    height: '400px',
                    transition: 'transform 0.3s ease',
                    ':hover': { transform: 'scale(1.02)' }
                  }}
                />
              </picture>
            </div>
          </RenderIf>

          <div className="collg7" style={{ flex: '1' }}>
            <div
              style={{
                marginBottom: '2.5rem',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
              >
                <h1
                  style={{
                    fontSize: '2.5rem',
                    color: '#2C3E50',
                    fontWeight: '600',
                    letterSpacing: '-0.5px',
                    margin: 0
                  }}
                >
                  {fetchCategory?.category?.category_name}
                </h1>
                <span
                  style={{
                    padding: '0.5rem 1rem',
                    borderRadius: '20px',
                    fontSize: '0.9rem',
                    fontWeight: '500',
                    backgroundColor:
                      fetchCategory?.category?.is_active === 1
                        ? '#e6f7ef'
                        : '#fde8e8',
                    color:
                      fetchCategory?.category?.is_active === 1
                        ? '#2ecc71'
                        : '#e74c3c'
                  }}
                >
                  {fetchCategory?.category?.is_active === 1
                    ? 'Active'
                    : 'Inactive'}
                </span>
              </div>

              <Button
                color="bg-titary"
                label="Update"
                onClick={() => setIsOpenModal(!isOpenModal)}
                style={{
                  padding: '1rem 2rem',
                  fontSize: '1rem',
                  borderRadius: '8px',
                  fontWeight: '500',
                  transition: 'transform 0.2s ease',
                  ':hover': { transform: 'translateY(-2px)' }
                }}
              />
            </div>

            <hr
              style={{
                margin: '2rem 0',
                opacity: 0.1,
                border: 'none',
                height: '1px',
                backgroundColor: '#2C3E50'
              }}
            />

            <div style={{ marginTop: '2rem' }}>
              <h6
                style={{
                  marginBottom: '1rem',
                  fontSize: '1.2rem',
                  color: '#2C3E50',
                  fontWeight: '600'
                }}
              >
                Description
              </h6>
              <p
                style={{
                  lineHeight: '1.8',
                  color: '#505965',
                  fontSize: '1rem'
                }}
              >
                {fetchCategory?.category?.category_description}
              </p>
            </div>

            <RenderIf isTrue={fetchCategory?.categoryType?.length > 0}>
              <div
                style={{
                  marginTop: '2.5rem',
                  padding: '1.5rem',
                  backgroundColor: '#f8f9fa',
                  borderRadius: '10px'
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '1rem'
                  }}
                >
                  <h6
                    style={{
                      fontSize: '1.2rem',
                      color: '#2C3E50',
                      fontWeight: '600',
                      margin: 0
                    }}
                  >
                    Category types
                  </h6>
                  <RsuiteButton
                    onClick={() => {
                      reset();
                      setDesiredAction('addTypes');
                      setOpenTypesModal(true);
                    }}
                    appearance="ghost"
                    style={{
                      padding: '8px 16px',
                      color: '#8e44ad',
                      borderColor: '#8e44ad',
                      transition: 'all 0.2s ease',
                      fontWeight: 500
                    }}
                  >
                    + Add more types
                  </RsuiteButton>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '0.75rem'
                  }}
                >
                  {fetchCategory?.categoryType?.map((type, index) => (
                    <p
                      key={index}
                      className="ff_semi"
                      style={{
                        padding: '0.75rem 1rem',
                        backgroundColor: '#fff',
                        borderRadius: '6px',
                        color: '#505965',
                        fontSize: '0.95rem',
                        boxShadow: '0 1px 3px rgba(0,0,0,0.05)',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      {type?.category_type_name}
                      <RsuiteButton
                        onClick={() => {
                          setCategoryTypeId(type?.id);
                          setDesiredAction('updateType');
                          setOpenTypesModal(true);
                          setUpdatedTypeName(type?.category_type_name);
                        }}
                        appearance="subtle"
                        style={{
                          padding: '4px 12px',
                          color: '#8e44ad',
                          transition: 'all 0.2s ease',
                          marginLeft: '8px',
                          fontWeight: 500
                        }}
                      >
                        Edit
                      </RsuiteButton>
                    </p>
                  ))}
                </div>
              </div>
            </RenderIf>
          </div>
        </div>
      </RenderIf>

      {isOpenModal && (
        <AddMenuCatgory
          {...{
            branchData,
            branchLoading,
            isLoading: uploadImageLoading,
            addMenu,
            additionType,
            uploadImage,
            setUploadImageLoading,
            setUploadImage,
            isOpenModal,
            onClose: () => setIsOpenModal(false),
            colorsList,
            existingCategory: fetchCategory,
            action: 'update'
          }}
        />
      )}

      {openTypesModal && (
        <RsuiteModal
          open={openTypesModal}
          onClose={() => setOpenTypesModal(false)}
        >
          <RsuiteModal.Header>
            <RsuiteModal.Title>
              {desiredAction === 'addTypes'
                ? 'Add more types'
                : desiredAction === 'updateType'
                ? 'Update type'
                : 'Add more types'}
            </RsuiteModal.Title>
          </RsuiteModal.Header>
          <RsuiteModal.Body style={{ overflowY: 'auto' }}>
            <RenderIf isTrue={desiredAction === 'updateType'}>
              <div style={{ display: 'flex', gap: '8px' }}>
                <div style={{ flex: 1 }}>
                  <label>Name:</label>
                  <Input
                    defaultValue={updatedTypeName}
                    onChange={(val) => setUpdatedTypeName(val)}
                  />
                </div>
              </div>
            </RenderIf>
            <RenderIf isTrue={desiredAction === 'addTypes'}>
              <form onSubmit={handleSubmit(addTypes)}>
                {fields?.map((field, index) => {
                  return (
                    <div className="mt_4" key={field.id}>
                      <TextField
                        type="text"
                        name={`types.${index}.type`}
                        label={'Type Name'}
                        placeholder="Pizza"
                        register={register}
                        validation={{ required: true }}
                        error={
                          errors?.types?.[index]?.type?.type === 'required'
                            ? 'Type name is required'
                            : ''
                        }
                      />
                      <RenderIf isTrue={fields?.length === 1}>
                        <div className="flex item_hend mt_10">
                          <button
                            type="button"
                            className="alink"
                            color="lableColor"
                            onClick={() =>
                              append({
                                type: ''
                              })
                            }
                          >
                            + Add another type
                          </button>
                        </div>
                      </RenderIf>
                      <RenderIf
                        isTrue={
                          fields?.length > 1 && index + 1 !== fields?.length
                        }
                      >
                        <div className="flex item_hend mt_10">
                          <button
                            type="button"
                            className="alink"
                            color="lableColor"
                            onClick={() => remove(index)}
                          >
                            - Remove type
                          </button>
                        </div>
                      </RenderIf>
                      <RenderIf
                        isTrue={
                          fields?.length > 1 && index + 1 === fields?.length
                        }
                      >
                        <div className="flex item_hend gap_16 mt_10">
                          <button
                            type="button"
                            className="alink"
                            color="lableColor"
                            onClick={() =>
                              append({
                                type: ''
                              })
                            }
                          >
                            + Add another type
                          </button>
                          <button
                            type="button"
                            className="alink"
                            color="lableColor"
                            onClick={() => remove(index)}
                          >
                            - Remove type
                          </button>
                        </div>
                      </RenderIf>
                    </div>
                  );
                })}
              </form>
            </RenderIf>
          </RsuiteModal.Body>
          <RsuiteModal.Footer>
            <div
              style={{
                display: 'flex',
                gap: '8px',
                justifyContent: 'flex-end'
              }}
            >
              <RsuiteButton
                onClick={() => {
                  if (desiredAction === 'addTypes') {
                    handleSubmit(addTypes)();
                  }
                  if (desiredAction === 'updateType') {
                    updateType();
                  }
                }}
                type={
                  desiredAction === 'addTypes'
                    ? 'submit'
                    : desiredAction === 'updateType'
                    ? 'submit'
                    : 'button'
                }
                appearance="ghost"
                loading={updating}
              >
                {desiredAction === 'addTypes' ? 'Add' : 'Update'}
              </RsuiteButton>
              <RsuiteButton
                onClick={() => setOpenTypesModal(false)}
                appearance="subtle"
              >
                Cancel
              </RsuiteButton>
            </div>
          </RsuiteModal.Footer>
        </RsuiteModal>
      )}
    </article>
  );
};
